import {useState} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {Checkbox} from "@mui/material";
import StatusLabel from "./statuslabel";
import {statuslabelstyle, subtaskitem, taskstyle} from "./planningstyles";
import {usePlanningContext} from "../contexts/planningContext";
import Button from "@mui/material/Button";
import {useAppContext} from "../contexts/appContext";

function BGColor(str) {
    switch(str){
        case "Planned":
            return " bg-bgplanned "
        case "In Progress":
            return " bg-bgprogress "
        case "Under Review":
            return " bg-bgreview "
        case "Completed":
            return " bg-bgcompleted "
        case "Cancelled":
            return " bg-bgcancelled "
        default:
            return " bg-bg-header "
    }
}

export default function PlannedTask(props) {
    const {task} = props;
    const {TrackTask, trackedtasks} = useAppContext();
    const {SaveTask, openTask, setOpenTask, tvOpen, setTvOpen,  ntype, setNtype} = usePlanningContext();
    const [tasksExpanded, setTasksExpanded] = useState(false);
    const [statOpen, setStatOpen] = useState(false);

    function StHeader(){
        if(!task || !task.subtasks) return "0/0";
        let completed = 0;
       let total = task.subtasks.length;
       for(let i = 0; i < task.subtasks.length; i++){
           if(task.subtasks[i].status === "completed") completed++;
       }
       return completed + "/" + total;
    }

    return (
        <div className={BGColor(task.status) + taskstyle + (tasksExpanded ? " h-[400px]" : " h-[250px]")}>
            <div className={"flex flex-row h-10 w-full justify-between items-center"}>
                <p className={"flex flex-row h-10 w-auto items-center justify-start text-sm font-semibold uppercase"}>
                    {task.title}
                </p>
                <div onClick={() => {setStatOpen(!statOpen)}}>{StatusLabel(task.status)}</div>
            </div>
            {statOpen && <div className={"flex flex-wrap w-full justify-end items-end h-auto gap-1 text-xs border-t border-b border-border py-2"}>
                <StatusButton task={task} SaveTask={SaveTask} stat={"Planned"} />
                <StatusButton task={task} SaveTask={SaveTask} stat={"In Progress"} />
                <StatusButton task={task} SaveTask={SaveTask} stat={"Under Review"} />
                <StatusButton task={task} SaveTask={SaveTask} stat={"Completed"} />
                <StatusButton task={task} SaveTask={SaveTask} stat={"Cancelled"} />
            </div>}
            <p className={"flex flex-wrap h-12 w-full text-sm font-semibold"}>
                {task.description}
            </p>
            <p className={"flex flex-wrap h-12 w-full text-xs font-semibold"}>
                Submitted at {new Date(task.created_at).toLocaleString("en-uk",{ hour12: false })}
            </p>
            <div
                className={"flex flex-row select-none justify-center items-center border border-border " + (tasksExpanded ? " bg-primary" : " bg-secondary")}
                onClick={() => setTasksExpanded(!tasksExpanded)}>
                {StHeader()} subtasks completed.
            </div>

            {tasksExpanded && <div className={"flex flex-col h-full w-full overflow-y-auto select-none"}>
                <List dense={true}>

                    {task.subtasks.map((el, ind) => (
                        <ListItem key={ind} className={subtaskitem}>
                            <Checkbox checked={el.status === "completed"}
                                      onChange={(e) => {
                                          if(el.status === "completed") {
                                              let sts = task.subtasks;
                                              let nt = {};
                                              nt.id = task.id;
                                              nt.title = task.title;
                                              nt.description = task.description;
                                              nt.priority = task.priority;
                                              nt.status = task.status;
                                              sts[ind].status = "incomplete";
                                              nt.subtasks = sts;
                                              SaveTask(nt)
                                          } else {
                                              let sts = task.subtasks;
                                              let nt = {};
                                              nt.id = task.id;
                                              nt.title = task.title;
                                              nt.description = task.description;
                                              nt.priority = task.priority;
                                              nt.status = task.status;
                                              sts[ind].status = "completed";
                                              nt.subtasks = sts;
                                              SaveTask(nt)
                                          }
                                      }} size={"small"}/><p
                            className={"flex flex-row h-auto w-full text-xs"}>{el.text}</p>
                        </ListItem>
                    ))}
                </List>
            </div>}
            <div className={"flex flex-row justify-between items-end gap-1 h-10 w-full text-sm"}>
                <p className={"text-sm italic"}>Priority:</p>
                <p className={"text-sm font-semibold"}>{task.priority}</p>
            </div>
            <div className={"flex flex-row justify-between items-end gap-1 h-10 w-full text-sm"}>
                <p className={"text-sm italic"}>Assignees:</p>
                <p className={"text-sm font-semibold"}>Xenthy</p>
            </div>
            <div className={"flex flex-row h-10 w-full justify-around items-center"}>
                <Button onClick={() => {
                    if(!tvOpen){
                        setOpenTask(task);
                        setTvOpen(task);
                    }
                }} >Edit</Button>
                <div className={"text-xs"}><Checkbox size={"small"} onChange={(e)=> TrackTask(task.id)} checked={trackedtasks.includes(task.id)} />Tracked</div>
            </div>
        </div>
    )
}

function StatusButton(props){
    const {task, SaveTask, stat} = props;
    return (
        <div onClick={() => {
            if (task.status !== stat) {
                let nt = {};
                nt.id = task.id;
                nt.title = task.title;
                nt.description = task.description;
                nt.priority = task.priority;
                nt.status = stat;
                nt.subtasks = task.subtasks;
                SaveTask(nt)
            }
        }} className={statuslabelstyle + (task.status === stat ? "bg-primary" : "bg-secondary")}>{stat}</div>
    )
}