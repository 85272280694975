import {createContext, useContext, useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast";

const AppContext = createContext({});
const appname = "AetherManager";

// @ts-ignore
const AppContextProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userPanelOpen, setUserPanelOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [userlistOpen, setuserListOpen] = useState(false);
    const [friends, setFriends] = useState([]);
    const [trackedtasks, setTrackedTasks] = useState([]);

    function profile() {
        if (session) {
            let prof = {};
            prof.id = session.user.id;
            prof.username = session.user.user_metadata.full_name;
            prof.email = session.user.email;
            prof.avatar = session.user.user_metadata.avatar_url;
            prof.discordID = session.user.user_metadata.provider_id;
            return prof;
        } else {
            return {};
        }
    }

    async function TrackTask(taskid) {
        if (trackedtasks.includes(taskid)) {
            let tasks = [];
            for (let i = 0; i < trackedtasks.length; i++) {
                if (trackedtasks[i] !== taskid) tasks.push(trackedtasks[i]);
            }
            const {error} = await supabase
                .from('userdata')
                .update({"trackedtasks": tasks})
                .eq('user_id', profile().id)
            if (error) {

            } else {
                setTrackedTasks(tasks);
                toast.success('Task untracked.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
            }
        } else {
            let tasks = [];
            for (let i = 0; i < trackedtasks.length; i++) {
                tasks.push(trackedtasks[i]);
            }
            tasks.push(taskid);
            const {error} = await supabase
                .from('userdata')
                .update({trackedtasks: tasks})
                .eq('user_id', profile().id)
            if (error) {

            } else {
                setTrackedTasks(tasks);
                toast.success('Task tracked.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
            }
        }
    }

    async function GetMyData(){
            const { data, error } = await supabase
                .from('userdata')
                .select()
                .eq('user_id', session.user.id);
            if(error){
                setFriends([]);
                setTrackedTasks([]);
            } else {
                if(data.length > 0){
                    console.log(data[0])
                    setFriends(data[0].friends);
                    setTrackedTasks(data[0].trackedtasks)
                } else {
                    await supabase
                        .from('userdata')
                        .insert({ friends: [], trackedtasks: []});
                    setFriends([]);
                    setTrackedTasks([])
                }
            }
    }

    let route = useLocation();
    if(route.pathname === "/") {
        document.title = appname + ' || Home';
    } else {
        let rt = route.pathname.split("/")[1];
        let fl = rt.charAt(0).toUpperCase();
        let fr = rt.slice(1);
        document.title = appname + ' || ' + (fl + fr);
    }

    const openPage = () => {
        if (route.pathname === "/") {
            return "landing";
        } else {
            return route.pathname.split("/")[1];
        }
    }

    useEffect(() => {
        if (isInitialLoad) {
            supabase.auth.getSession().then(({data: {session: any}}) => {
                setSession(session);
            })
        }
        const sub = supabase.auth.onAuthStateChange(async(_event, _session) => {
            setSession(_session);
            if (!_session) {
                setIsAdmin(false);
                if (isInitialLoad) setIsInitialLoad(false);
                if (loadingInitial) setLoadingInitial(false);
            }
        });
        return (() => {
            sub.data.subscription.unsubscribe();
        });
    }, []);

    useEffect(() => {
        async function run(){
           await supabase.rpc('is_admin').then(result => {
                if(result.error){
                    setIsAdmin(false);
                } else setIsAdmin(result.data);
            });
            GetMyData();
            if (isInitialLoad) setIsInitialLoad(false);
            if (loadingInitial) setLoadingInitial(false);
        }

        if(session){
            run();
        }
    }, [session]);

    return (
        <AppContext.Provider
            value={{
                session,
                profile,
                isAdmin,
                loadingInitial,
                userPanelOpen,
                setUserPanelOpen,
                friends,
                openPage,
                appname,
                trackedtasks,
                TrackTask,
                chatOpen,
                setChatOpen,
                userlistOpen,
                setuserListOpen
            }} >
            {children}
        </AppContext.Provider>)
}

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };