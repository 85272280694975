import {useState} from "react";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import {ArrowLeft, ArrowRight, TableRows, ViewColumn} from "@mui/icons-material";
import Dashboard from "./dashboard";
import {usePlanningContext} from "../contexts/planningContext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import Taskboard from "./taskboard";
import TaskView from "./taskview";

export default function Planning(props){
    const {activeTaskBoard, tvOpen, viewMode, setViewMode} = usePlanningContext();
    const [sbOpen, setSbOpen] = useState(false);
    const [openSection, setOpenSection] = useState("dashboard");

    function PageTitle(str){
        if(str === "dashboard") return "Dashboard";
        else return "TaskBoard - " + activeTaskBoard.boardname;
    }

    return (
        <div className={"flex flex-col w-full h-full text-textprimary overflow-hidden"}>
            <div
                className={"flex flex-row w-full h-14 justify-between items-center bg-footer border-b border-border gap-4"}>
                <div className={"flex flex-row w-auto h-full justify-center items-center"}>
                    <Button onClick={() => {
                        setSbOpen(!sbOpen)
                    }}>{sbOpen ? <ArrowLeft/> : <ArrowRight/>}</Button>
                    <p className={"flex flex-row h-full w-auto justify-center items-center"}>{PageTitle(openSection)}</p>
                </div>
                <div className={"flex flex-row w-auto h-auto"}>
                    {viewMode === "cols" ? <Button data-tooltip-id={"tooltip"} data-tooltip-content={"Toggle Row view"}
                                                    data-tooltip-place={"left"} onClick={()=> setViewMode("rows")} ><TableRows/></Button> :
                        <Button data-tooltip-id={"tooltip"} data-tooltip-content={"Toggle Column view"}
                                data-tooltip-place={"left"}  onClick={()=> setViewMode("cols")} ><ViewColumn/></Button>}
                </div>
                </div>
                <div className={"flex flex-row w-full h-full overflow-hidden"}>
                    <div className={"flex flex-col pt-10 h-full bg-header " + (sbOpen ? "w-1/5" : "w-16")}>
                        <ButtonBar openSection={openSection} setOpenSection={setOpenSection} sbOpen={sbOpen}/>
                    </div>
                    <div className={"flex flex-col h-full overflow-hidden " + (sbOpen ? "w-4/5" : "w-full")}>
                        {openSection === "dashboard" && activeTaskBoard ? <Dashboard/> : <Taskboard/>}
                    </div>
                </div>
                {tvOpen && <TaskView/>}
            </div>
            )
            }

            function ButtonBar(props) {
            const {taskboards, activeTaskBoard, setActiveTaskboard} = usePlanningContext();
    const {sbOpen, setOpenSection, openSection} = props;
    return (
        <div className={"flex flex-col gap-2"}>
            <Button color={openSection === "dashboard" ? "inherit" : "primary"} onClick={() => setOpenSection("dashboard")}
                data-tooltip-id={"tooltip"}
                    data-tooltip-content={"Dashboard"}
                    data-tooltip-place={"right"}><HomeIcon/>{sbOpen && "Dashboard"}</Button>
            {taskboards && taskboards.map((tb,ind) => (
                <Button color={(openSection === "taskboard" && activeTaskBoard === tb) ? "inherit" : "primary"}
                    onClick={() => { setActiveTaskboard(tb); setOpenSection("taskboard") }}
                    data-tooltip-id={"tooltip"}
                        data-tooltip-content={tb.boardname}
                        data-tooltip-place={"right"} key={ind}><DashboardIcon/>{sbOpen && tb.boardname}</Button>
            ))}
        </div>
    )
}