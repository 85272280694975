import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import {useProjectsContext} from "./projectsContext";
import toast from "react-hot-toast";

const PlanningContext = createContext({});

const PlanningContextProvider = ({ children }) => {
    const {activeProject} = useProjectsContext();
    const [taskboards, setTaskboards] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);
    const [activeTaskBoard, setActiveTaskboard] = useState(null);
    const [openTask, setOpenTask] = useState(null);
    const [tvOpen, setTvOpen] = useState(false);
    const [ntype, setNtype] = useState("Planned");
    const [viewMode, setViewMode] = useState(localStorage.getItem("viewmode") ? localStorage.getItem("viewmode") : "cols");

    useEffect(() => {
       if(localStorage.getItem("viewmode") !== viewMode){
           localStorage.setItem("viewmode", viewMode);
       }
    }, [viewMode]);

    useEffect(() => {
        if(taskboards.length > 0) setActiveTaskboard(taskboards[0]);
        else setActiveTaskboard(null);
    }, [taskboards]);

    async function PostNewTask(task){
        task.project = activeProject.id;
        task.boardid = activeTaskBoard.id;
        const {error} = await supabase.from('tasks').insert(task);
        if(error){
            toast.error('Failed to post task.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return false;
        }
        toast.success('Posted task successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
        return true;
    }

    async function UpdateTask(task){
        let tsks = [];
        const _tasks = tasks;
        let found = false;
        console.log(tasks);
        for(let i = 0; i < _tasks.length; i++){
            if(_tasks[i].id === task.id){
                tsks.push(task);
                found = true;
            } else tsks.push(_tasks[i]);
        }
        if(!found) tsks.push(task);
        setTasks(tsks);
    }

    async function SaveTask(task){
        let nt = {};
        nt.title = task.title;
        nt.description = task.description;
        nt.subtasks = task.subtasks;
        nt.priority = task.priority;
        nt.status = task.status;
        try {
            const { error } = await supabase
                .from('tasks')
                .update(nt)
                .eq('id', task.id)
            if(error){

            } else {
                toast.success('Edited task successfully.', {
                    duration: 4000,
                    position: 'bottom-right',
                });
                if(tvOpen){
                    setTvOpen(false);
                }
            }
        } catch (error) {
            toast.error('Failed to edit.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    async function GetTasks() {
        try {
            const { data, error } = await supabase
                .from('tasks')
                .select()
                .eq("boardid", activeTaskBoard.id)
            if(error){
                setLoadingTasks(false);
            } else {
                setTasks(data);
                setLoadingTasks(false);
            }
        } catch (error) {
            setLoadingTasks(false);
        }
    }

    useEffect(() => {
        if(activeTaskBoard) {
            GetTasks();
            const channel = supabase
                .channel('tasks')
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: "tasks",
                    },(payload) => GetTasks()
                )
                .subscribe()
            return () => {
                supabase.removeChannel(channel);
            };
        }
    }, [activeTaskBoard]);

    useEffect(() => {
        if(activeProject) GetBoards();
    }, [activeProject]);

    async function GetBoards() {
        setLoadingTasks(true);
        try {
            const { data, error } = await supabase
                .from('taskboards')
                .select()
                .eq("project", activeProject.id)
            if(error){
                setLoadingTasks(false);
                setTaskboards([]);
            } else {
                setTaskboards(data);
            }
        } catch (error) {
            setTaskboards([]);
            setLoadingTasks(false);
        }
    }

    return (
        <PlanningContext.Provider
            value={{
                loadingTasks,
                taskboards,
                activeTaskBoard,
                setActiveTaskboard,
                tasks,
                openTask,
                setOpenTask,
                PostNewTask,
                SaveTask,
                tvOpen,
                setTvOpen,
                ntype,
                setNtype,
                viewMode,
                setViewMode
            }}>
            {children}
        </PlanningContext.Provider>
    )
}

const usePlanningContext = () => useContext(PlanningContext);

export { PlanningContext as default, PlanningContextProvider, usePlanningContext };