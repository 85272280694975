import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const NewsContext = createContext({});

const NewsContextProvider = ({ children }) => {
    const [feedarticles, setFeedarticles] = useState([]);
    const [loadingNews, setLoadingNews] = useState(false);


    useEffect(() => {
        GetFeed();
        const channel = supabase
            .channel('newsfeed')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "newsfeed"
                },
                (payload) => GetFeed()
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
        };
    }, []);

    async function GetFeed() {
        setLoadingNews(true);
        try {
            const { data, error } = await supabase
                .from('newsfeed')
                .select()
            if(error){
                setLoadingNews(false);
            } else {
                setFeedarticles(data);
                setLoadingNews(false);
            }
        } catch (error) {
            setLoadingNews(false);
        }
    }

    return (
        <NewsContext.Provider
            value={{
                loadingNews,
                feedarticles,
                GetFeed
            }}>
            {children}
        </NewsContext.Provider>
    )
}

const useNewsContext = () => useContext(NewsContext);

export { NewsContext as default, NewsContextProvider, useNewsContext };