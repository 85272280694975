import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const ProjectsContext = createContext({});

const ProjectsContextProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [activeProject, setActiveProject] = useState(null);

    useEffect(() => {
       if(projects.length > 0) setActiveProject(projects[0]);
       else setActiveProject(null);
    }, [projects]);

    useEffect(() => {
        GetProjects();
    }, []);

    async function GetProjects() {
        setLoadingProjects(true);
        try {
            const { data, error } = await supabase
                .from('projects')
                .select()
            if(error){
                setLoadingProjects(false);
            } else {
                setProjects(data);
                setLoadingProjects(false);
            }
        } catch (error) {
            setLoadingProjects(false);
        }
    }

    return (
        <ProjectsContext.Provider
            value={{
                loadingProjects,
                projects,
                GetProjects,
                activeProject,
                setActiveProject
            }}>
            {children}
        </ProjectsContext.Provider>
    )
}

const useProjectsContext = () => useContext(ProjectsContext);

export { ProjectsContext as default, ProjectsContextProvider, useProjectsContext };