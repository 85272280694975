import React from "react";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAppContext} from "../contexts/appContext";
import PatchNoteDisplay from "./patchnotedisplay";
import Button from "@mui/material/Button";

export default function ArticleView(props) {
    const {art, setEditing} = props;
    const {session, username, isAdmin} = useAppContext();

    async function SubmitDelete() {
        if(!session || !isAdmin) return;
        const {error} = await supabase.from('newsfeed')
            .delete()
            .eq('id', art.id);

        if(error){
            toast.error('Failed to delete.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Deleted news article successfully.', {
            duration: 4000,
            position: 'bottom-right',
        });
    }

    return (
        <article className="pt-6 flex flex-row h-auto w-full">
            <div className="xl:flex flex-col h-auto w-full border-slate-700 border-b">
                <div className="grow pb-6 px-6">
                    <div className="space-y-3">
                        {art.type === "Patch Notes" ?
                            <PatchNoteDisplay art={art}/> :
                            <>
                                <header>
                                    <h2 className="text-2xl font-bold mb-3">{art.title}</h2>
                                </header>
                                {art.content}
                            </>
                        }
                    </div>
                </div>

                {session && isAdmin &&<div className="flex flex-row w-full h-10 shrink-0 px-4 justify-center items-center">
                    <Button
                        className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            setEditing(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{transition: "all .15s ease"}}
                        onClick={() => {
                            SubmitDelete();
                        }}
                    >
                        Delete
                    </Button>
                </div>}
            </div>
        </article>
    )
}
