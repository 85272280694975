import {useState} from "react";
import {useAppContext} from "../contexts/appContext";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SendIcon from "@mui/icons-material/Send";
import {getDateTimeFromStamp} from "../utils/gettimestamp";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export default function ChatWindow(props){
    return (
        <div className={"absolute flex flex-col text-textprimary w-full lg:w-[600px] h-[70%] lg:h-[600px] bottom-12 right-0 bg-footer border-border border lg:rounded-tl-2xl overflow-hidden"}>
            <div className={"flex flex-row h-12 w-full bg-slate-900 border-border border-b justify-between items-center px-2"}>
                <p></p>
                <p>Chat</p>
                <div className={"flex flex-row h-full w-auto justify-center gap-1 items-center"}>
                    <div
                        className={"flex flex-row justify-center items-center text-textprimary h-7 w-7 rounded-lg bg-secondary hover:bg-opacity-60"}>
                        <PeopleAltIcon />
                    </div>
                    <div
                        className={"flex flex-row justify-center items-center text-textprimary h-7 w-7 rounded-lg bg-secondary hover:bg-opacity-60"}>
                        <ViewQuiltIcon/>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col w-full h-full"}>
                <ChatScreen/>
            </div>
        </div>
    )
}

function ChatScreen(props) {
    const {openChannel} = props;
    const {profile} = useAppContext();
    const [scrollPos, setScrollPos] = useState(0);
    const [responseText, setResponseText] = useState("");

    return (
        <div className={"flex flex-col items-center justify-start h-full text-sm lg:text-md w-full overflow-hidden"}>
            <div className={"flex flex-row h-full w-full pt-2 pl-2 overflow-hidden"}>
                <div id="messagecontainer" onScroll={(e) => {
                    setScrollPos(e.target.scrollTop);
                }}
                     className={"flex flex-col items-start justify-start h-full w-full overflow-y-auto overflow-x-hidden bg-base-100 rounded-t-xl"}>
                    <div
                        className={"flex flex-col items-start gap-4 justify-start h-auto w-full py-2 lg:py-4 px-4 lg:px-8"}>
                        {openChannel && openChannel.messages.map((message, ind) => (
                            <div id={"msg" + ind} key={"msg" + ind}
                                 className={"flex flex-col w-[80%] h-auto p-2 lg:p-4 rounded-xl bg-opacity-80 " + (message.sender === profile().email ? " bg-secondary" : " bg-base-300 self-end")}>
                                <div className={"flex-col w-full h-auto items-end text-xs px-2 justify-start"}>
                                    <div
                                        className={"flex flex-row gap-3 w-full h-auto " + (message.sender !== profile().email ? "justify-end" : "justify-start")}>
                                        <p className={"text-sm lg:text-md font-bold"}>{message.staffresponse &&
                                            <AdminPanelSettingsIcon />}{message.displayname + " <" + (message.staffresponse ? "info@tech-mania.be" : message.sender) + ">"}</p>
                                    </div>
                                    <div
                                        className={"flex flex-row justify-start items-start italic w-full h-auto " + (message.sender !== profile().email ? "justify-end" : "justify-start")}>
                                        {(message.staffresponse ?  "Staff Member" : "Member")}
                                    </div>
                                </div>
                                <div className={"flex-wrap h-auto w-full text-sm lg:text-md px-2 py-4"}>
                                    {message.message}
                                </div>
                                <p className={"flex flex-row text-xs items-center w-full justify-end"}>{getDateTimeFromStamp(message.date)}</p>
                            </div>
                        ))}
                        <div id={"messagesend"} className={"h-1 w-1"}></div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-col items-start justify-start h-20 w-full pl-2 pb-2 overflow-hidden border-t border-border"}>
                <div className={"flex flex-col w-full h-full bg-base-100 rounded-b-xl py-1 pr-4 pl-3"}>
                    <div className={"flex w-full h-full items-center justify-center gap-2 flex-row py-2"}>
                            <textarea value={responseText} rows={2}
                                      onChange={(e) => {
                                          setResponseText(e.currentTarget.value)
                                      }}
                                      className={"textareatickets flex bg-secondary text-textprimary border-border border rounded-xl w-full h-full p-2"}/>
                        <div
                            className={"btn btn-secondary btn-md flex flex-row w-auto h-auto justify-center items-center tooltip tooltip-left"}
                            onClick={() => {

                            }} data-tip={"Send message"}>
                            <SendIcon size={"small"}/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}