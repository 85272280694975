import {labelstyle} from "./planningstyles";

export default function StatusLabel(str) {
    switch (str) {
        case "Planned":
            return (
                <p className={labelstyle + "text-white bg-planned"}>Planned</p>
            )
        case "In Progress":
            return (
                <p className={labelstyle + "text-white bg-progress"}>In Progress</p>
            )
        case "Under Review":
            return (
                <p className={labelstyle + "text-white bg-review"}>Under Review</p>
            )
        case "Completed":
            return (
                <p className={labelstyle + "text-white bg-completed"}>Completed</p>
            )
        case "Cancelled":
            return (
                <p className={labelstyle + "text-white bg-cancelled"}>Cancelled</p>
            )
        default:
            return (
                <p className={labelstyle + "text-white bg-primary"}>Planned</p>
            )
    }
}