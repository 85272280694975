import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';
import {AppContextProvider} from "./contexts/appContext";
import { BrowserRouter } from 'react-router-dom';
import {NewsContextProvider} from "./contexts/newsContext";
import {ProjectsContextProvider} from "./contexts/projectsContext";
import {PlanningContextProvider} from "./contexts/planningContext";
import {DocContextProvider} from "./docs/docContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
      <AppContextProvider>
          <NewsContextProvider>
              <ProjectsContextProvider>
                  <PlanningContextProvider>
                      <DocContextProvider>
                          <App />
                          <Tooltip id="tooltip" />
                      </DocContextProvider>
                  </PlanningContextProvider>
              </ProjectsContextProvider>
          </NewsContextProvider>
      </AppContextProvider>
      </BrowserRouter>
  </React.StrictMode>
);
