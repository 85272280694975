import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import { EditorContent, useEditor } from '@tiptap/react'
import MenuBar from "./MenuBar";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {useDocContext} from "./docContext";
import Button from "@mui/material/Button";
import {useProjectsContext} from "../contexts/projectsContext";
import parse from 'html-react-parser';
import TextAlign from "@tiptap/extension-text-align";
import {Close, SearchRounded} from "@mui/icons-material";

const extensions = [
    StarterKit.configure(),
    Highlight,
    TaskList,
    TaskItem,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    CharacterCount.configure({
        limit: 10000,
    })
]

export default function Docs(props) {
    const {openPage, SetOpenPage, pages, cats, GetPages, SavePage} = useDocContext();
    const {isAdmin} = useAppContext();
    const [editing, setEditing] = useState(false);
    const [npOpen, setNpOpen] = useState(false);
    const [tempTitle, setTempTitle] = useState("");
    const [tempSubtitle, setTempSubTitle] = useState("");
    const [tempDescription, setTempDescription] = useState("");
    const [tempCategory, setTempCategory] = useState("");
    const [editors, setEditors] = useState([]);
    const [html, setHtml] = useState("<p></p>")
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const editor = useEditor({
        extensions: extensions,
        editorProps: {
        attributes: {
            class: 'prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
        },
    },
    });

    async function Save(){
        let pg = {};
        pg.content = editor.getHTML();
        pg.title = tempTitle;
        pg.subtitle = tempSubtitle;
        pg.description = tempDescription
        await SavePage(openPage.id, pg).then(result => {
            if(result) {
                SetOpenPage(openPage.id);
                setEditing(false);
            }
        });
    }

    useEffect(() => {
        if(openPage){
            if(editor) editor.commands.setContent(openPage.content);
            setTempTitle(openPage.title);
            setTempSubTitle(openPage.subtitle);
            setTempDescription(openPage.description);
            setTempCategory(openPage.category);
            setEditors(openPage.editors);
            setHtml(openPage.content);
        } else {
            setHtml("<p></p>");
            setTempTitle("FrontPage");
            setTempSubTitle("");
            setTempDescription("Gateway to the Docs system");
            setTempCategory("");
            setEditors([]);
        }
    }, [openPage]);

    return (
        <div className="flex flex-row w-full h-full overflow-hidden">
            <div className={"flex flex-col justify-start py-4 overflow-hidden items-center h-full w-[300px] bg-footer border-r border-border"}>
                <div className={"flex flex-row w-full h-14 justify-center items-center text-xl font-semibold uppercase text-textprimary border-b border-border"}>
                    Navigation
                </div>
                <div className={"flex flex-col justify-start pt-10 overflow-y-auto items-center h-full w-full"}>
                    <div className={"flex flex-row w-full h-10 justify-center items-center px-4"}>
                        <div onClick={() => SetOpenPage("")}
                             className={"flex flex-row h-8 text-xs w-full justify-center items-center text-textprimary border border-border " + (!openPage ? "bg-secondary hover:bg-opacity-60" : " hover:bg-primary bg-opacity-60")}>
                        Frontpage
                    </div>
                    </div>
                    <div className={"flex flex-col h-auto w-full justify-start items-center"}>
                        {cats && cats.map((cat, ind) => (
                            <div className={"flex flex-col w-full h-auto gap-1 px-4"} key={ind}>
                                <p className={"flex flex-row h-12 w-full justify-center text-sm items-center text-textprimary"}>{cat}</p>
                                {GetPages(cat) && GetPages(cat).map(page => (
                                    <div onClick={() => SetOpenPage(page.id)} key={page.id} className={"flex flex-row h-8 text-xs w-full justify-center items-center text-textprimary border border-border " + (openPage && openPage.id === page.id ? "bg-secondary hover:bg-opacity-60" : " hover:bg-primary bg-opacity-60")}>{page.title}</div>
                                ))}
                            </div>))}
                    </div>
                </div>
                <div className={"flex flex-row w-full h-14 justify-center items-center text-xl font-semibold uppercase text-textprimary border-t border-border"}>
                    <Button onClick={() => setNpOpen(true)}>New Page</Button>
                </div>
            </div>
            {editing ?
                <div className={"flex flex-col h-full w-full p-6"}>
                    {editor && <MenuBar editor={editor}/>}
                    <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                        <div className={"flex flex-col h-full w-full text-textprimary"}>
                            <EditorContent height={"100%"} className="editor__content" editor={editor}/>
                        </div>
                    </div>
                    <div className={"flex flex-row justify-between items-center h-20 w-full border-t border-border"}>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                        <Button onClick={() => Save()}>Save</Button>
                    </div>
                </div> :
                <div className={"flex flex-col h-full overflow-y-auto w-full p-4 text-textprimary"}>
                    <div className={"flex flex-row h-16 w-full justify-between items-center border-b border-border"}>
                        <div className={"flex flex-row h-14 w-auto gap-6 justify-center items-center font-semibold text-shadow-sm shadow-black"}>
                            <p className={"flex flex-row h-full w-auto text-2xl justify-end items-center"}>{tempTitle}</p>
                            <p className={"flex flex-row h-8 w-auto text-sm justify-end items-end"}>{tempSubtitle}</p>
                        </div>
                        <div className={"flex flex-row h-8 text-sm w-auto justify-end items-center italic"}>{tempDescription}</div>
                    </div>
                    {isAdmin && openPage && <div className={"flex flex-row justify-end items-center h-12 w-full px-2"}><Button
                        onClick={() => setEditing(!editing)}>Edit</Button></div>}
                    <div className={"flex flex-col h-auto w-full"}>
                        {openPage ? parse(html) : <Frontpage />}
                    </div>
                </div>}
            <div className={"absolute flex flex-row justify-center items-center overflow-hidden text-textprimary h-10 left-2 top-[95px] " + (searchOpen ? "bg-primary rounded-xl w-[240px]" : "bg-secondary rounded-full  hover:bg-opacity-50 w-10")}>
                {searchOpen ? <div className={"flex flex-row w-full h-full justify-center items-center"}>
                        <input
                            onBlur={() => setSearchOpen(false)}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.currentTarget.value);
                            }}
                            type="text"
                            className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Type and press enter"
                            style={{transition: "all .15s ease"}}
                        />
                        <div onClick={() => setSearchOpen(false)}
                             className={"flex flex-row w-8 h-full justify-center items-center hover:bg-secondary hover:bg-opacity-60"}>
                            <Close className={"h-8 w-8"} fontSize={"small"} />
                        </div>
                    </div> :
                    <div className={"flex flex-row w-full h-full justify-center items-center"} onClick={() => setSearchOpen(true)}><SearchRounded /></div>}
            </div>
            {npOpen && <NewPageModal setNpOpen={setNpOpen}/>}
        </div>
    )
}

function Frontpage(props) {
    return (
        <div className='flex flex-col h-full w-full justify-start items-center'>
            <p className='flex flex-row h-20 w-full justify-center items-center'>
                Welcome to the AetherDocs frontpage.
            </p>
            <p className={"flex flex-row h-20 w-full justify-center items-end"}>
                This system is still under development and only has basic editing features right now.
            </p>
            <p className={"flex flex-row h-20 w-full justify-center items-end"}>
                Create or open a page from the navigation menu to left.
            </p>
        </div>
    )
}

function NewPageModal(props) {
    const {profile} = useAppContext();
    const {projects} = useProjectsContext();
    const {PostNewPage} = useDocContext();
    const [tempTitle, setTempTitle] = useState("");
    const [tempSubtitle, setTempSubTitle] = useState("");
    const [tempDescription, setTempDescription] = useState("");
    const [tempCategory, setTempCategory] = useState("");
    const [editors, setEditors] = useState([profile.id]);
    const {setNpOpen} = props;
    const content = "<div></div>"

    async function Post(){
        if(tempTitle.length > 1 && tempSubtitle.length > 1 && tempCategory.length){
            let page = {};
            page.title = tempTitle;
            page.subtitle = tempSubtitle;
            page.description = tempDescription;
            page.category = tempCategory;
            page.editors = editors;
            page.project = 0;
            page.content = content;
            await PostNewPage(page).then(result => {
                if(result){
                    setTempTitle("");
                    setTempSubTitle("");
                    setTempDescription("");
                    setTempCategory("");
                } else {

                }
            })
        }
    }

    return (
        <div className={"absolute flex flex-col justify-center items-center h-screen w-screen right-0 top-0"}>
            <div className={"absolute flex h-full w-full bg-black bg-opacity-25"} onClick={(e) => {if(e.currentTarget.focus) setNpOpen(false)}}></div>
            <div
                className={"flex flex-col z-40 h-[600px] w-[600px] text-textprimary right-0 top-32 bg-footer border border-border px-10"}>
                <div className={"flex flex-row h-14 w-full justify-center items-center text-lg font-semibold"}>
                    New Page
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    Title:
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    <input
                        value={tempTitle}
                        onChange={(e) => {
                            setTempTitle(e.currentTarget.value);
                        }}
                        type="text"
                        className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Title"
                        style={{transition: "all .15s ease"}}
                    />
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    Category:
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    <input
                        value={tempCategory}
                        onChange={(e) => {
                            setTempCategory(e.currentTarget.value);
                        }}
                        type="text"
                        className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Title"
                        style={{transition: "all .15s ease"}}
                    />
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    Subtitle:
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    <input
                        value={tempSubtitle}
                        onChange={(e) => {
                            setTempSubTitle(e.currentTarget.value);
                        }}
                        type="text"
                        className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Subtitle"
                        style={{transition: "all .15s ease"}}
                    />
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    Description:
                </div>
                <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                    <input
                        value={tempDescription}
                        onChange={(e) => {
                            setTempDescription(e.currentTarget.value);
                        }}
                        type="text"
                        className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Description"
                        style={{transition: "all .15s ease"}}
                    />
                </div>
                <div className={"flex flex-row h-20 w-full justify-center items-center"}>
                 <Button onClick={()=> Post()}>Create</Button>
                </div>
            </div>
        </div>
    )
}