export const labelstyle = "flex flex-row select-none h-6 w-auto px-2 justify-center items-center rounded-lg hover:bg-opacity-60  ";
export const colstyle = "flex flex-col drop-shadow-lg shadow-black overflow-hidden w-[330px] h-full bg-slate-800 rounded-2xl border border-border p-2";
export const colheaderstyle = "flex flex-row text-shadow shadow-black h-10 w-full justify-between items-center uppercase text-lg font-bold border-b border-border"
export const taskliststyle = "flex flex-col gap-2 h-auto w-full justify-start items-center p-2";
export const boxstyle = "flex flex-col bg-footer rounded-2xl overflow-hidden border border-border p-2";
export const taskstyle = " flex flex-col w-[300px] overflow-hidden drop-shadow text-shadow-sm shadow-black text-white border border-border rounded-xl p-2 gap-1 ";
export const subtaskitem = "flex flex-row h-6 w-full";
export const statuslabelstyle = "flex flex-row w-full justify-center items-center p-1 lg:w-1/2 hover:bg-opacity-60 "
export const rowstyle = "flex flex-col drop-shadow-lg shadow-black overflow-hidden w-full h-[330px] bg-slate-800 rounded-2xl border border-border";
export const rowheaderstyle = "flex flex-row text-shadow shadow-black h-10 w-auto justify-between items-center uppercase text-lg font-bold border-b border-border"
export const rowtaskliststyle = "flex flex-row gap-2 h-full w-auto justify-start items-center p-2";
