import React, {useEffect, useState} from "react";
import ArticleView from "./articleview";
import ArticleEdit from "./articleEdit";
import {useAppContext} from "../contexts/appContext";
import PatchNoteEditor from "./patchnoteEditor";

export default function Article(props) {
    const {art} = props;
    const {session, isAdmin} = useAppContext();

    const [editing, setEditing] = useState(false);

    if(editing) {
        if(art.type === "Patch Notes"){
            return <PatchNoteEditor art={art} setEditing={setEditing} />
        } else {
            return  <ArticleEdit art={art} setEditing={setEditing} />
        }
    } else {
        return <ArticleView art={art} setEditing={setEditing} />
    }
}