import {useProjectsContext} from "../contexts/projectsContext";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";

export default function Projects(props){
    const {projects, loadingProjects, activeProject} = useProjectsContext();
    const [tempName, setTempName] = useState("");
    const [tempDesc, setTempDesc] = useState("");

    useEffect(() => {
        if(activeProject){
            setTempName(activeProject.projectname);
            setTempDesc(activeProject.projectdescription);
        } else {
            setTempName("");
            setTempDesc("");
        }
    }, [activeProject]);

    if(loadingProjects){
        return (<div className={"flex h-full w-full justify-center items-center flex-col"}>
            <div className="flex flex-col gap-4 w-52">
                <div className="skeleton h-32 w-full"></div>
                <div className="skeleton h-4 w-28"></div>
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-full"></div>
            </div>
        </div>)
    } else return (
        <div className={"flex flex-row h-full w-full"}>
            <div className={"flex flex-col h-full w-1/5 bg-footer border-r border-border text-primary p-6"}>
                <div className={"flex flex-col h-[300px] w-full items-start"}>
                    <p className={"text-xl font-bold"}>Projects:</p>
                    <div className={"flex flex-col h-full w-full py-2 overflow-y-auto"}>
                        <div className={"flex flex-col h-auto w-full"}>
                            {projects && projects.map(project => (
                                <div className={"flex flex-row h-[60px] w-full justify-center items-center " + (activeProject === project ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary hover:bg-opacity-70" )} key={project.id}>
                                    <p className={"text-xl font-semibold"}>{project.projectname}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={"flex flex-row h-12 w-full justify-between items-center"}>
                    <Button>Create New</Button>
                </div>
            </div>
            <div className={"flex flex-col h-full w-4/5 text-textprimary px-6 lg:px-10"}>
                <div className={"flex flex-row h-[150px] justify-between items-center"}>
                    <p></p>
                    <p className={"flex flex-row w-1/4 h-full justify-end items-end text-5xl"}>{activeProject && activeProject.projectname}</p>
                </div>
                <div className={"flex flex-row h-[80px] justify-between items-center border-b border-border"}>
                    <p></p>
                    <p className={"flex flex-row w-1/4 h-full justify-end items-center text-lg"}>
                        {activeProject && activeProject.projectdescription}
                    </p>
                </div>
                <div className={"flex flex-col h-full w-full justify-start items-center py-6 gap-3"}>
                    <div className={"flex flex-col h-auto w-4/5 gap-2"}>
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold"
                            htmlFor="projectname"
                        >
                            Project Name
                        </label>
                        <input
                            value={tempName}
                            onChange={(e) => {
                                setTempName(e.currentTarget.value);
                            }}
                            type="text"
                            className="border-0 px-3 py-3 placeholder-gray-400 text-textprimary bg-primary rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Project Name"
                            style={{transition: "all .15s ease"}}
                        />
                    </div>
                    <div className={"flex flex-col h-auto w-4/5 gap-2"}>
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold"
                            htmlFor="title"
                        >
                            Project Description
                        </label>
                        <textarea
                            value={tempDesc}
                            onChange={(e) => {
                                setTempDesc(e.currentTarget.value);
                            }}
                            className="border-0 px-3 py-3 min-h-40 placeholder-gray-400 text-textprimary bg-primary rounded text-sm shadow focus:outline-none focus:ring w-full"
                            placeholder="Project Description"
                            style={{transition: "all .15s ease"}}
                        />
                    </div>
                    <div className={"flex flex-col w-4/5 h-full"}>
                        <div className={"flex flex-row w-full h-14 text-textprimary text-sm font-bold uppercase"}>Members</div>
                    </div>
                </div>
            </div>
        </div>
    )
}