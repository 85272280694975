import toast, { Toaster } from 'react-hot-toast';
import {useEffect, useState} from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import About from "./pages/about";
import HomeIcon from '@mui/icons-material/Home';
import {useAppContext} from "./contexts/appContext";
import UserPanel from "./components/userpanel";
import {Route, Routes} from "react-router-dom";
import Projects from "./projects/projects";
import Planning from "./planning/planning";
import Docs from "./docs/docs";
import BugTracker from "./bugtracker/bugtracker";
import Estore from "./estore/estore";
import ChatWindow from "./chat/chatwindow";
import GameHome from "./pages/gamehome";

function App() {
    const {loadingInitial, session, userPanelOpen, setUserPanelOpen, chatOpen, setChatOpen, openPage} = useAppContext();

    return (
        <div className="flex flex-col h-screen w-screen overflow-hidden">
            {openPage() !== "game" && <Header />}
            <div className={"flex flex-row h-full w-full bg-background overflow-hidden"}>
                <div className={"flex flex-col h-full overflow-hidden " + (session && userPanelOpen ? " w-4/5" : "w-full")}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/store' element={<Estore />} />
                        <Route path='/game' element={<GameHome />} />
                        {session && <Route path='/planning' element={<Planning />} />}
                        {session && <Route path='/projects' element={<Projects />} />}
                        {session && <Route path='/docs' element={<Docs />} />}
                        {session && <Route path='/bugtracker' element={<BugTracker />} />}
                        {session && <Route path='/cb' element={<Home />} />}
                    </Routes>
                </div>
                {session && userPanelOpen &&
                    <div className={"flex flex-col h-full w-1/5"}>
                        <UserPanel />
                    </div>}
            </div>
            {chatOpen && <ChatWindow />}
            {openPage() !== "game" && <Footer/>}
            <Toaster/>
        </div>
    );
}

export default App;
