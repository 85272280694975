import NewsFeed from "../news/newsfeed";

export default function Home(props){
    return (
        <div className="flex flex-col h-full w-full overflow-y-auto">
            <div className="flex flex-col w-full min-h-full h-auto justify-center items-center">
                <NewsFeed />
            </div>
        </div>
    )
}