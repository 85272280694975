export function getDateTimeFromStamp(timestamp) {
    const months = [
        "januari", "februari", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];

    const currentDate = new Date(timestamp);
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');

    const dateTimeString = `Time: ${hours}:${minutes}:${seconds} Date: ${day} ${month} ${year}`;
    return dateTimeString;
}