import {boxstyle, subtaskitem, taskstyle} from "./dashboard";
import {useState} from "react";
import {colheaderstyle, colstyle, rowheaderstyle, rowstyle, rowtaskliststyle, taskliststyle} from "./planningstyles";
import PlannedTask from "./plannedtask";
import Button from "@mui/material/Button";
import {AddCircleRounded} from "@mui/icons-material";
import {usePlanningContext} from "../contexts/planningContext";

export default function Taskboard(props){
    const {viewMode} = usePlanningContext();

    return (
        <div className="flex flex-col w-full h-full overflow-hidden">{viewMode === "cols" ? <ColumnView /> : <RowView />}</div>
    )
}

function RowView(props) {
    return (
        <div className={"flex flex-col w-full h-full overflow-y-auto"}>
            <div className={"flex flex-col w-full h-auto gap-5 justify-start items-center p-4"}>
                <Row str={"Planned"} />
                <Row str={"In Progress"} />
                <Row str={"Under Review"} />
                <Row str={"Completed"} />
                <Row str={"Cancelled"} />
            </div>
        </div>
    )
}

function Row(props){
    const {tasks, setActiveTask, activeTask} = usePlanningContext();
    const {str} = props;
    return (
        <div className={rowstyle}>
            <RowHeader str={str}/>
            <div className={"flex flex-row w-full h-full overflow-x-auto py-1"}>
                <div className={rowtaskliststyle}>
                    {tasks && tasks.map((el,ind) => el.status === str &&
                        <PlannedTask key={ind} task={el}/>)}
                </div>
            </div>
        </div>
    )
}

function RowHeader(props) {
    const {str} = props;
    const {tvOpen, setTvOpen,  ntype, setNtype, setOpenTask} = usePlanningContext();
    return (
        <div className={rowheaderstyle}>
            <p className={"w-20"}></p>
            <p className={"w-auto"}>{str}</p>
            <p className={"w-20"}>
                <Button data-tooltip-id={"tooltip"} data-tooltip-content={"Add new task with status " + str}
                        data-tooltip-place={"right"}
                        onClick={()=> {
                            if(!tvOpen) {
                                setOpenTask(null);
                                setNtype(str);
                                setTvOpen(true);
                            }
                        }}
                >
                    <AddCircleRounded/>
                </Button>
            </p>
        </div>
    )
}

function ColumnView(props) {
    return (
        <div className={"flex flex-wrap w-full h-full overflow-y-auto gap-5 justify-center items-center p-4"}>
            <Column str={"Planned"} />
            <Column str={"In Progress"} />
            <Column str={"Under Review"} />
            <Column str={"Completed"} />
            <Column str={"Cancelled"} />
        </div>
    )
}
function ColHeader(props) {
    const {str} = props;
    const {tvOpen, setTvOpen,  ntype, setNtype, setOpenTask} = usePlanningContext();
    return (
        <div className={colheaderstyle}>
            <p className={"w-20"}></p>
            <p className={"w-auto"}>{str}</p>
            <p className={"w-20"}>
                <Button data-tooltip-id={"tooltip"} data-tooltip-content={"Add new task with status " + str}
                        data-tooltip-place={"right"}
                onClick={()=> {
                    if(!tvOpen) {
                        setOpenTask(null);
                        setNtype(str);
                        setTvOpen(true);
                    }
                }}
                >
                    <AddCircleRounded/>
                </Button>
            </p>
        </div>
    )
}
function Column(props){
    const {tasks, setActiveTask, activeTask} = usePlanningContext();
    const {str} = props;
    return (
        <div className={colstyle}>
            <ColHeader str={str}/>
            <div className={"flex flex-col w-full h-full overflow-y-auto py-1"}>
                <div className={taskliststyle}>
                    {tasks && tasks.map((el,ind) => el.status === str &&
                        <PlannedTask key={ind} task={el}/>)}
                </div>
            </div>
        </div>
    )
}