import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useAppContext} from "../contexts/appContext";
import {Avatar} from "@mui/material";
import {Link} from "react-router-dom";

export default function Header(props){
    const {loadingInitial, session, userPanelOpen, setUserPanelOpen, profile, openPage, appname} = useAppContext();

    return (
        <div className={"flex flex-row h-20 w-full bg-header justify-between items-center"}>
            <div className={"flex flex-row h-full gap-2 w-1/4 justify-start items-center py-4 pl-4"}>
                <div className={"flex flex-row justify-center items-center w-14 h-14 bg-slate-700 bg-opacity-25 rounded-full"}>
                    <img alt={"logo"} src={"./logo192.png"} className="h-12 w-auto object-cover"/>
                </div>
                <p className={"flex flex-row h-full w-auto justify-center items-center text-textprimary text-xl"}>{appname}</p>
            </div>

            <div className={"flex flex-row h-full w-1/2 justify-center items-center gap-2"}>
                <Link to={"/"} >
                <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "landing" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                    Home
                </div>
                </Link>
                <Link to={"/store"} >
                    <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "store" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                        E-Store
                    </div>
                </Link>
                {session && <Link to={"/planning"} >
                    <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "planning" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                        Planning
                    </div>
                </Link>}
                <Link to={"/docs"} >
                    <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "docs" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                        Documentations
                    </div>
                </Link>
                {session &&  <Link to={"/bugtracker"} >
                    <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "bugtracker" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                        Bug Tracker
                    </div>
                </Link>}
                {session &&  <Link to={"/projects"} >
                    <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "projects" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                        Projects
                    </div>
                </Link>}
                <Link to={"/about"} >
                <div className={"flex flex-row h-12 w-auto justify-center px-4 uppercase items-center hover:opacity-70 " +
                        (openPage() === "about" ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}>
                    About
                </div>
                </Link>
            </div>

            <div className={"flex flex-row h-full w-1/4 justify-end items-center gap-2 pr-4"}>
                <div
                    className={"flex flex-row h-12 w-12 rounded-lg justify-center items-center hover:opacity-70 " + (userPanelOpen ? "bg-primary text-textprimary" : "bg-secondary text-textsecondary")}
                    data-tooltip-id={"tooltip"}
                     data-tooltip-content={session ? profile().username : "Discord Login"}
                     data-tooltip-place={"left"}
                     onClick={() => {
                    if(!session) {
                        supabase.auth.signInWithOAuth({
                            provider: 'discord'
                        }).then(result => {
                            if(result.error){
                                toast.error('Failed to log in.', {
                                    duration: 4000,
                                    position: 'bottom-right',
                                });
                            } else {

                            }
                        })
                    }
                    else {
                        setUserPanelOpen(!userPanelOpen);
                    }
                }}>
                    {session ? <Avatar alt={profile().username} src={profile().avatar} /> : <LoginIcon />}
                </div>
            </div>
        </div>
    )
}