import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {Checkbox} from "@mui/material";
import {boxstyle, subtaskitem, taskstyle} from "./planningstyles";
import PlannedTask from "./plannedtask";
import {useAppContext} from "../contexts/appContext";
import {usePlanningContext} from "../contexts/planningContext";

export default function Dashboard(props){
    const {trackedtasks} = useAppContext();
    const {tasks} = usePlanningContext();

    function TrackedTasks(){
        let tsks = [];
        for(let i = 0; i < tasks.length; i++){
            if(trackedtasks.includes(tasks[i].id)) tsks.push(tasks[i]);
        }
        return tsks;
    }

    return (
        <div className="flex flex-col h-full w-full overflow-y-auto p-5 text-textprimary">
            <div className={"flex flex-wrap gap-2 overflow-hidden justify-center items-center w-full h-auto"}>
                <div className={"flex flex-col overflow-hidden w-full h-[330px] " + boxstyle}>
                    <div className={"flex flex-row h-10 w-full items-center uppercase text-lg font-bold"}>
                        <p className={"ml-2 border-b border-border"}>Active Tasks</p>
                    </div>
                    <div className={"flex flex-row w-full h-full overflow-x-auto"}>
                        <div className={"flex flex-row gap-2 h-full w-auto justify-start items-center px-2"}>
                            {tasks && TrackedTasks().map((el,ind) =>
                                <PlannedTask key={ind} task={el}/>)}
                        </div>
                    </div>
                </div>
                <div className={"w-1/5 h-[300px] " + boxstyle}>

                </div>
                <div className={"w-1/5 h-[300px] " + boxstyle}>

                </div>
                <div className={"w-1/5 h-[300px] " + boxstyle}>

                </div>
                <div className={"w-1/5 h-[300px] " + boxstyle}>

                </div>
            </div>
        </div>
    )
}