import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {usePlanningContext} from "../contexts/planningContext";
import {Checkbox} from "@mui/material";
import {Close, DeleteForever} from "@mui/icons-material";

export default function TaskView(props) {
    const {openTask, setOpenTask, tasks, PostNewTask, SaveTask, ntype, tvOpen, setTvOpen} = usePlanningContext();
    const [tempName, setTempName] = useState("");
    const [tempDescription, setTempDescription] = useState("");
    const [priority, setPriority] = useState("Normal");
    const [status, setStatus] = useState(ntype);
    const [subtasks, setSubTasks] = useState([]);

        useEffect(() => {
            if(openTask){
                setTempName(openTask.title);
                setTempDescription(openTask.description);
                setPriority(openTask.priority);
                setStatus(openTask.status);
                setSubTasks(openTask.subtasks);
            } else {
                setTempName("");
                setTempDescription("");
                setPriority("Normal");
                setStatus(ntype);
                setSubTasks([]);
            }
        }, [openTask]);

        useEffect(() => {

        }, [subtasks]);

        async function PostTask(){
            let nt = {};
            nt.title = tempName;
            nt.description = tempDescription;
            nt.subtasks = subtasks;
            nt.priority = priority;
            nt.status = status;
            await PostNewTask(nt).then(result => {
                if(result){
                    setTempName("");
                    setTempDescription("");
                    setPriority("Normal");
                    setStatus("Planned");
                    setSubTasks([]);
                    setTvOpen(false);
                }
            });
        }

        async function SaveTaskChanges(){
            let nt = {};
            nt.id = openTask.id;
            nt.title = tempName;
            nt.description = tempDescription;
            nt.subtasks = subtasks;
            nt.priority = priority;
            nt.status = status;
            SaveTask(nt);
        }

    return (
        <div className={"absolute flex flex-col w-[600px] h-full right-0 top-0 bg-background border-l border-b border-t border-border p-4"}>
            <div className={"flex flex-row h-20 w-full justify-between items-center text-2xl text-shadow shadow-black border-b border-border"}>
                <Button onClick={()=> {
                    setTvOpen(false);
                    setOpenTask(null);
                }}><Close /></Button>
                <p>{openTask ? "Manage Task" : "New Task"}</p>
            </div>

            <div className={"flex flex-row h-12 gap-2 w-full"}>
                <div className={"flex flex-row h-12 gap-2 w-1/2 justify-center items-center"}>
                    <p>Task Priority:</p>
                    <select className="select select-bordered w-1/2 text-white bg-secondary border-border border"
                            value={priority}
                            onChange={(e) => {
                                setPriority(e.currentTarget.value);
                            }}
                            style={{transition: "all .15s ease"}}
                    >
                        <option>Low</option>
                        <option>Normal</option>
                        <option>High</option>
                        <option>Critical</option>
                    </select>
                </div>

                <div className={"flex flex-row h-12 gap-2 w-1/2 justify-center items-center"}>
                    <p>Task Status:</p>
                    <select className="select select-bordered bg-secondary border-border border w-1/2 text-white"
                            value={status}
                            onChange={(e) => {
                                setStatus(e.currentTarget.value);
                            }}
                            style={{transition: "all .15s ease"}}
                    >
                        <option >Planned</option>
                        <option >In Progress</option>
                        <option >Under Review</option>
                        <option >Completed</option>
                        <option >Cancelled</option>
                    </select>
                </div>
            </div>

            <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                Task Name:
            </div>
            <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                <input
                    value={tempName}
                    onChange={(e) => {
                        setTempName(e.currentTarget.value);
                    }}
                    type="text"
                    className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Task Name"
                    style={{transition: "all .15s ease"}}
                />
            </div>
            <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                Task Description:
            </div>
            <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                <input
                    value={tempDescription}
                    onChange={(e) => {
                        setTempDescription(e.currentTarget.value);
                    }}
                    type="text"
                    className="bg-secondary border-border border px-3 py-3 placeholder-gray-400 text-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Task Name"
                    style={{transition: "all .15s ease"}}
                />
            </div>
            <div className={"flex flex-row h-12 w-full justify-start items-center"}>
                Sub Tasks:
            </div>
            <div className={"flex flex-col w-[90%] h-[400px] overflow-y-auto"}>
                {subtasks.map((st, ind) => <Subtask subtasks={subtasks} setSubTasks={setSubTasks} st={st} ind={ind} />)}
                <div className={"flex flex-row h-10 w-full justify-center items-center"}>
                    <Button onClick={()=> {
                        let sts = [];
                        subtasks.forEach((el) => { sts.push(el); })
                        let ntask = {status: "incomplete", text: "New subtask"}
                        sts.push(ntask);
                        setSubTasks(sts);
                    }}>
                        Add
                    </Button>
                </div>
            </div>
            <div className={"flex flex-row h-24 w-full justify-center items-center"}>
                {openTask ? <Button onClick={()=> { SaveTaskChanges(); }} >Save task</Button> : <Button onClick={()=> { PostTask(); }} >Add new task</Button>}
            </div>
        </div>
    )
}

function Subtask(props){
    const {subtasks, setSubTasks, ind, st} = props;
    const [tempSt, setTempSt] = useState(st.text);
    const [tempStatus, setTempStatus] = useState(st.status);

    function SetST(){
        let sts = [];
        subtasks.forEach((el,inde) => {
            if(ind === inde) sts.push( { text: tempSt, status: tempStatus });
            else sts.push(el)
        });
        setSubTasks(sts);
    }

    function removeST(){
        let sts = [];
        subtasks.forEach((el,inde) => {
            if(ind !== inde) sts.push(el)
        });
        setSubTasks(sts);
    }

    return (
        <div className={"flex flex-row gap-2 justify-center items-center w-full h-10 px-2"}>
            {ind+1}.
            <input key={ind} type="text" placeholder="Type here" value={tempSt} onChange={e => {
                setTempSt(e.currentTarget.value);
            }} onBlur={e => {
                SetST();
            }}
                   className="w-full h-auto rounded-xl bg-secondary border border-border"/>
            <Button onClick={()=> {  removeST(); }}><DeleteForever /></Button>
        </div>
    )
}