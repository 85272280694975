import './editor.scss';
import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

const DocContext = createContext({});

const DocContextProvider = ({ children }) => {
    const [pages, setPages] = useState([]);
    const [cats, setCats] = useState([]);
    const [loadingDocs, setLoadingDocs] = useState(false);
    const [openPage, setOpenPage] = useState(null);

    useEffect(() => {
        GetDocs();
        const channel = supabase
            .channel('docs')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "docs"
                },
                (payload) => GetDocs()
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
        };
    }, []);

    async function SetOpenPage(pid){
        if(pid === "") setOpenPage(null);
        else {
            for(let i = 0; i < pages.length; i++){
                if(pages[i].id === pid) {
                    setOpenPage(pages[i]);
                    break;
                }
            }
        }
    }

    function GetPages(cat){
        let cont = [];
        for(let i = 0; i < pages.length; i++){
            if(pages[i].category === cat) cont.push(pages[i]);
        }
        return cont;
    }

    async function SavePage(id, page){
        const {error} = await supabase.from('docs').update(page).eq('id', id);
        if(error){
            toast.error('Failed to save page: ' + error, {
                duration: 4000,
                position: 'bottom-right',
            });
            return false;
        }

        toast.success('Page successfully saved.', {
            duration: 4000,
            position: 'bottom-right',
        });
        return true;
    }

    async function PostNewPage(page){
        const {error} = await supabase.from('docs').insert(page);
        if(error){
            toast.error('Failed to create page: ' + error, {
                duration: 4000,
                position: 'bottom-right',
            });
            return false;
        }
        toast.success('Page successfully created.', {
            duration: 4000,
            position: 'bottom-right',
        });
        return true;
    }

    async function GetDocs() {
        setLoadingDocs(true);
        try {
            const { data, error } = await supabase
                .from('docs')
                .select()
            if(error){
                setLoadingDocs(false);
            } else {
                setPages(data);
                let _cats = [];
                for(let i = 0; i < data.length; i++){
                    if(!_cats.includes(data[i].category)) _cats.push(data[i].category);
                }
                setCats(_cats);
                setLoadingDocs(false);
                if(openPage){
                    for(let i = 0; i < data; i++){
                        if(openPage.id === data[i].id) {
                            setOpenPage(data[i]);
                            break;
                        }
                    }
                }
            }
        } catch (error) {
            setLoadingDocs(false);
        }
    }

    return (
        <DocContext.Provider
            value={{
                loadingDocs,
                pages,
                cats,
                GetDocs,
                SetOpenPage,
                openPage,
                PostNewPage,
                GetPages,
                SavePage
            }}>
            {children}
        </DocContext.Provider>
    )
}

const useDocContext = () => useContext(DocContext);

export { DocContext as default, DocContextProvider, useDocContext };