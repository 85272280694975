import {Auth} from "@supabase/auth-ui-react";
import {supabase} from "../utils/supabase";
import {ThemeSupa} from "@supabase/auth-ui-shared";
import {useAppContext} from "../contexts/appContext";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {Avatar, Checkbox, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {useState} from "react";

export default function UserPanel(props){
    const {loadingInitial, session, profile,setUserPanelOpen} = useAppContext();

    return (
        <div
            className="text-primary flex flex-col overflow-hidden h-full w-full py-4 px-10 bg-footer border-l border-border">
            <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                <div className={"flex flex-col w-full h-auto text-textprimary"}>
                    <div className={"flex flex-row h-12 text-lg justify-start items-center font-bold"}>Account Details</div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>ID:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().id}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Discord ID:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().discordID}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Discord Username:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().username}</p>
                    </div>
                    <div className={"flex flex-row h-12 w-full justify-between"}>
                        <p className={"flex flex-row h-full justify-center items-center text-center"}>Discord Email:</p>
                        <p className={"flex flex-row h-full justify-center items-center text-xs text-center"}>{profile().email}</p>
                    </div>
                    <div className={"flex flex-row h-12 text-lg justify-start items-center font-bold"}>Contacts</div>
                    <div className={"flex flex-col w-full h-[300px] overflow-y-auto"}>
                        <CheckboxListSecondary/>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row h-16 w-full justify-center items-center"}>
                <Button onClick={() => {
                    supabase.auth.signOut();
                    toast.success('Logged out.', {
                        duration: 4000,
                        position: 'bottom-right',
                    });
                }}>Log out</Button>
            </div>
        </div>
    )

}

function CheckboxListSecondary() {
    const {friends, profile} = useAppContext();
    const [checked, setChecked] = useState([1]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <List dense sx={{ width: '100%', height: 'auto' }}>
            <ListItem
                key={profile().id}
                disablePadding
            >
                <ListItemButton>
                    <ListItemAvatar>
                        <Avatar
                            alt={`Avatar n°${profile().avatar}`}
                            src={profile().avatar}
                        />
                    </ListItemAvatar>
                    <ListItemText id={profile().id} primary={profile().username} />
                </ListItemButton>
            </ListItem>
            {friends && friends.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                    <ListItem key={value} disablePadding>
                        <ListItemButton>
                            <ListItemAvatar>
                                <Avatar
                                    alt={`Avatar n°${value + 1}`}
                                    src={`/static/images/avatar/${value + 1}.jpg`}
                                />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}