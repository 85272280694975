import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";

export default function ArticleEdit(props) {
    const {art, setEditing} = props;
    const [posterName, setPosterName] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const posttypes = ["type", "type"];
    const {session, isAdmin} = useAppContext();

    useEffect(() => {
        ResetEdits(art);
    }, [art]);

    function ResetEdits(ar) {
        setPosterName(ar.posterName);
        setTitle(ar.title);
        setMessage(ar.content);
        setType(ar.type);
    }

    async function SubmitEdit() {
        if(!session || !isAdmin) return;

        try {
            const { error } = await supabase
                .from('newsfeed')
                .update({ title: title, content: message, type: type, poster: posterName, edited_at: Date.now().toString() })
                .eq('id', art.id)
                if(error){

                } else {
                    toast.success('Edited news article successfully.', {
                        duration: 4000,
                        position: 'bottom-right',
                    });
                    setEditing(false);
                }
        } catch (error) {
            toast.error('Failed to edit.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    return (
        <div>
            <div className={"flex flex-col w-full h-screen p-4"}>
                <div className="w-full px-4">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-base-200">
                        <div className="flex-auto p-5 lg:p-10">
                            <label className="form-control w-full max-w-xs" htmlFor="type">
                                <select className="select select-bordered"
                                        value={type}
                                        onChange={(e) => {
                                            setType(e.currentTarget.value);
                                        }}
                                        style={{transition: "all .15s ease"}}
                                >
                                    <option disabled selected>Article type</option>
                                    <option>Announcements</option>
                                    <option>Bug Fixes</option>
                                    <option>Patch Notes</option>
                                </select>
                            </label>
                            <div className="relative w-full mb-3 mt-8">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="title"
                                >
                                    Title
                                </label>
                                <input
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.currentTarget.value);
                                    }}
                                    type="text"
                                    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                    placeholder="Article title"
                                    style={{transition: "all .15s ease"}}
                                />
                            </div>
                            <div className="relative w-full mb-3 mt-8">
                                <label
                                    className="block uppercase text-white text-xs font-bold mb-2"
                                    htmlFor="content"
                                >
                                    Content
                                </label>
                                <textarea id={"content"}
                                          className={"min-h-[200px] h-full w-full bg-[#244939] border border-[#51b78c] text-white"}
                                          value={message} onChange={(e) => {
                                    setMessage(e.currentTarget.value);
                                }}>

                            </textarea>
                            </div>

                            <div className="text-center mt-6">
                                <Button
                                    className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={() => {
                                        setEditing(false);
                                    }}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="bg-error text-white active:bg-error-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={() => {
                                        ResetEdits(art);
                                    }}
                                >
                                    Reset Edits
                                </Button>
                                <Button
                                    className="bg-primary text-white active:bg-primary-content text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    style={{transition: "all .15s ease"}}
                                    onClick={() => {
                                        SubmitEdit();
                                    }}
                                >
                                    Save Article
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}