import {ChatBubble} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {useAppContext} from "../contexts/appContext";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {getDateTimeFromStamp} from "../utils/gettimestamp";

const buttonstyle = "flex flex-row w-9 h-9 shadow-sm justify-center items-center border-border border rounded-xl hover:bg-opacity-50 "
export default function Footer(props){
    const {setChatOpen, chatOpen, userlistOpen, setuserListOpen} = useAppContext();
    return (
        <div className={"flex flex-row h-14 w-full bg-footer justify-between items-center text-textprimary border-t border-border px-2"}>
            <p className={"flex flex-row h-full w-[200px]"}></p>
            <p className={"flex flex-row w-auto h-full justify-center items-center uppercase text-xs font-semibold"}>Copyright &copy; AetherHouse Productions {new Date().getFullYear()}</p>
            <div className={"flex flex-row h-full w-[200px] justify-end gap-1 items-center"}>
                <div onClick={() => setuserListOpen(!userlistOpen)}
                     className={buttonstyle + (userlistOpen ? "bg-primary" : "bg-secondary")}>
                    <PeopleAltIcon size={"small"}/>
                </div>
                <div onClick={() => setChatOpen(!chatOpen)}
                     className={buttonstyle + (chatOpen ? "bg-primary" : "bg-secondary")}>
                    <ChatBubble size={"small"}/>
                </div>
                <div className={"flex flex-row justify-center items-center h-8 w-20 ml-1 border-border border bg-slate-700 bg-opacity-40"}>

                </div>
            </div>
        </div>
    )
}